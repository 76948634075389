import { defineStore } from 'pinia'

export const useIconStore = defineStore('icons', {
  state: () => ({
    count: 0,

    icons: JSON.parse(window.localStorage.getItem('collection.default')) ?? [],

  }),
  getters: {
    double: state => state.count * 2,
    getIcons: state => state.icons,
  },
  actions: {
    increment() {
      this.count++
    },

    addIcon(icon) {
      const found = this.icons.find(el => el == icon)
      if (found == undefined) {
        this.icons.push(icon);
        this.save();
      }
    },

    removeIcon(icon) {
      //
      this.icons = this.icons.filter(el => el != icon);
      this.save();
    },

    save() {
      window.localStorage.setItem('collection.default', JSON.stringify(this.icons))
    }
  },
})
