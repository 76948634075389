<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >

      <span class="text-h4">
        <v-icon large class="mb-1">mdi-emoticon-poop</v-icon>
        iconoclast
      </span>

      <span class="ml-3 d-sm-none">
        icon picker
      </span>
      <span class="ml-3 d-none d-sm-inline">
        Material icon picker
      </span>

      <v-spacer></v-spacer>


    </v-app-bar>

    <v-main>
      <v-container fluid>

        <v-row>
          <v-col cols="12" sm="8">

            <icon-search
                @select="onSelectIcon"
                @add="onAddIcon"
            ></icon-search>

          </v-col>
          <v-col cols="12" sm="4">

            <v-card elevation="3">

            <v-tabs fixed-tabs v-model="activeTab">

              <v-tab>About</v-tab>
              <v-tab>Collection</v-tab>

              <!-- about -->
              <v-tab-item>
                <v-card-text>
                  <p>
                    This is a faster Material Design Icon (mdi) search & select tool.  No fuss, no cruft - just a super
                    quick reference tool for finding the right icons as you're building your apps.
                  </p>
                  <p>
                    It presents the default set of mdi icons available to Vue projects as standard.
                  </p>
                  <p>
                    Search, mouseover on an icon, then...
                  </p>
                  <p>
                    <v-icon class="mr-2">mdi-content-copy</v-icon>copy to clipboard
                  </p><p>
                    <v-icon class="mr-2">mdi-plus-circle</v-icon>add to collection (see Collections tab)
                  </p>
                </v-card-text>
              </v-tab-item>

              <!-- collections -->
              <v-tab-item>

                  <icon-collection></icon-collection>

              </v-tab-item>

            </v-tabs>

            </v-card>


          </v-col>
        </v-row>


      </v-container>
    </v-main>

    <v-snackbar
        v-model="toasty"
        :multi-line="true"
        timeout="2000"
        rounded
        :color="toastyColor"
    >
      {{ toastyText }}

      <template v-slot:action="{ attrs }">
        <v-btn
            :color="'white'"
            icon
            v-bind="attrs"
            @click="toasty = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import {useIconStore} from "@/store";

import IconSearch from "@/components/IconSearch";
import IconCollection from "@/components/IconCollection";

import {EventBus} from "@/plugins/event-bus";

export default {
  name: 'App',

  components: {
    IconCollection,
    IconSearch
  },

  data() {
    return {
      activeTab: 0,

      toasty: false,
      toastyColor: 'green',
      toastyText: '',

    }
  },

  mounted() {


    this.$plausible.enableAutoPageviews();

    if (useIconStore().getIcons.length > 0) {
      this.activeTab = 1;
    }

    EventBus.$on('toast', (kind, message) => {
      //alert(kind + ' ' + message)
      if (kind == 'success') {
        this.toastyColor = 'green';
      } else if (kind == 'error') {
        this.toastyColor = 'red';
      } else {
        this.toastyColor = 'gray';
      }
      this.toastyText = message;
      this.toasty = true;
    });

  },

  methods: {
    onSelectIcon(icon) {
      console.log(icon);
    },
    onAddIcon(icon) {
      useIconStore().addIcon(icon);
      this.activeTab = 1;
    },
  },

};
</script>
