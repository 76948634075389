<template>
  <div>
    <v-card-text v-if="icons.length == 0">
      When you add icons, they will be listed here.  Their names will also be saved in your browser's <code>localStorage</code>
      so the list will still be here when you come back next time too.
    </v-card-text>
    <v-card-text v-if="icons.length > 0">
      You've added these icons.  Their names are saved in your browser's <code>localStorage</code> so this list will
      be here when you come back next time too:
    </v-card-text>
    <v-list v-if="icons.length > 0">
      <v-list-item
          v-for="icon in icons"
          :key="icon"
      >

        <v-list-item-icon>
          <v-icon large>{{icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <code>{{icon}}</code>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>

          <v-item-group>
            <v-btn
                icon
                small
                v-clipboard:copy="icon"
                v-clipboard:success="onCopy"
            >
              <v-icon small>mdi-content-copy</v-icon>
            </v-btn>

            <v-btn
                icon
                small
                @click.stop="removeIcon(icon)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>

          </v-item-group>

        </v-list-item-action>

      </v-list-item>
    </v-list>

  </div>
</template>

<script>
import {useIconStore} from "@/store";
import {EventBus} from "@/plugins/event-bus";

export default {
  name: "IconCollection",

  computed: {
    icons() {
      return useIconStore().getIcons;
    }
  },

  methods: {

    removeIcon(icon) {
      useIconStore().removeIcon(icon);
      EventBus.$emit('toast', 'success', 'Removed from collection');
    },

    onCopy() {
      EventBus.$emit('toast', 'success', 'Copied!');
    }

  }
}
</script>

<style scoped>

</style>
